import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SearchPage.module.css';
import { useMyContext } from '../../contexts/StateHolder';

function FilterFooter(props) {
  const { handleResetAll, onSetFilterModalOpen, totalItems } = props;
  const { setClearDeepFilters } = useMyContext();
  return (
    <div className={css.filterFooter}>
      <button
        className={css.resetAllButton}
        type="button"
        onClick={() => {
          handleResetAll();
          setClearDeepFilters(true);
        }}
      >
        <FormattedMessage id="SearchFiltersMobile.resetAll" />
      </button>
      <button
        className={css.showResults}
        type="button"
        onClick={() => {
          onSetFilterModalOpen();
          setClearDeepFilters(false);
        }}
      >
        <FormattedMessage id="SearchPageWithList.totalItems" values={{ totalItems }} />
      </button>
    </div>
  );
}

export default FilterFooter;
